import { twMerge } from 'tailwind-merge';

export const H1: React.FC<{ className?: string }> = ({
  children,
  className,
}) => (
  <h1
    className={twMerge(
      'text-grey-dark font-circular font-bold text-lg',
      className,
    )}
  >
    {children}
  </h1>
);

export const H2: React.FC<{ className?: string }> = ({
  children,
  className,
}) => (
  <h2
    className={twMerge('text-blue font-circular font-bold text-sm', className)}
  >
    {children}
  </h2>
);

export const ErrorMessage: React.FC<{ className?: string }> = ({
  children,
  className,
}) => <p className={twMerge('text-red text-sm', className)}>{children}</p>;
