import { useUserDetails } from '../UserDetailsController/useUserDetails';
import PageContent from '@componentsV2/PageContent';
import UserSidebar from './UserSidebar';
import UserDrawer from './UserDrawer';
import { AccountState } from '@pagesV2/UserPage/types';
import Policies from '../Sections/Policies';

type Props = {
  userId: string;
  accountState: AccountState;
};

const UserPage: React.FC<Props> = ({ userId, accountState }) => {
  const { drawerOpen, toggleDrawer, currentSection } = useUserDetails();

  return (
    <>
      <UserSidebar userId={userId} />
      <PageContent drawerOpen={drawerOpen}>
        {(() => {
          switch (currentSection) {
            case 'Policies':
              return <Policies userId={userId} accountState={accountState} />;
            default:
              return <>Section not supported</>;
          }
        })()}
      </PageContent>
      <UserDrawer
        userId={userId}
        open={drawerOpen}
        toggleDrawer={toggleDrawer}
      />
    </>
  );
};

export default UserPage;
