const Sidebar: React.FC = ({ children }) => (
  <>
    <div className="fixed inset-y-0 z-40 flex w-45 flex-col">
      <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-grey-light px-6 pb-4 border-r border-grey pt-20">
        <div className="py-6">{children}</div>
      </div>
    </div>
  </>
);

export default Sidebar;
