import { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { H2 } from './Typography';

type Props = {
  title: string;
  className?: string;
};

const SectionToggle: React.FC<Props> = ({ title, className, children }) => {
  const [contentVisible, setContentVisible] = useState(true);

  return (
    <div className={className}>
      <div className="flex mb-3">
        <div
          className="flex items-center cursor-pointer"
          onClick={() => setContentVisible(visible => !visible)}
        >
          <H2 className="mr-2">{title}</H2>
          {contentVisible ? (
            <ChevronDownIcon className="w-4 h-4" />
          ) : (
            <ChevronUpIcon className="w-4 h-4" />
          )}
        </div>
      </div>
      {contentVisible ? <>{children}</> : null}
    </div>
  );
};

export default SectionToggle;
